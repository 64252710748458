// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { Locale } from 'jsw';

import VIRTUAL_DIRECTORY_PAGE from 'queries/VirtualDirectoryPage.graphql';
import VIRTUAL_DIRECTORY_CONTENT_LIST from 'queries/VirtualDirectoryContentList.graphql';
import ASP_DOT_NET_DOMAIN_SETTINGS_PAGE from 'queries/AspDotNetDomainSettingsPage.graphql';
import VIRTUAL_DIRECTORY_PHP_PAGE from 'queries/VirtualDirectoryPhpPage.graphql';

import VirtualDirectorySidebar from '../components/sidebars/VirtualDirectorySidebar';

export const virtualDirectoryRoute = {
    path: 'virtual-directory',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
        'components/pages/VirtualDirectoryPage'
    ),
    loader: async ({ request: { url }, params: { domainId } }) => {
        const id = btoa(`Domain:${domainId}`);
        const path = new URL(url).searchParams.get('path') || '/';

        const [{ data }] = await Promise.all([
            apolloClient.query({
                query: VIRTUAL_DIRECTORY_PAGE,
                variables: { domainId: id, path },
            }),
            apolloClient.query({
                query: VIRTUAL_DIRECTORY_CONTENT_LIST,
                variables: {
                    domainId: id,
                    path,
                    input: readGraphQlListInputFromStorage({ key: `VirtualDirectoryPage${domainId}` }),
                },
            }),
        ]);

        if (!data.domain.hosting) {
            throw new Response('Not Found', { status: 404 });
        }
    },
    handle: {
        helpContext: 'client.domain.hosting.web-directories',
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('virtualDirectories'),
        }),
        pageTitle: ({ matchesContext, location: { search } }) => {
            if (!matchesContext.domainRouteData) {
                return null;
            }
            const { domain } = matchesContext.domainRouteData;
            const path = new URLSearchParams(search).get('path') || '/';
            const locale = Locale.getSection('components.routes');
            return locale.lmsg('virtualDirectoryDomain', { path, domainName: `<b>${domain.name}</b>` });
        },
        key: ({ params }) => params.domainId,
        secondary: ({ location: { search }, params: { domainId } }) => (
            <VirtualDirectorySidebar
                domainId={domainId}
                path={new URLSearchParams(search).get('path') || '/'}
            />
        ),
    },
    children: [{
        path: 'create-directory',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
            'components/pages/VirtualDirectoryPage/CreateDirectoryPage'
        ),
    }, {
        path: 'asp-dot-net',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
            'components/pages/VirtualDirectoryPage/AspDotNetSettingsPage'
        ),
        loader: async ({ request: { url }, params: { domainId } }) => {
            const path = new URL(url).searchParams.get('path') || '/';
            const { data } = await apolloClient.query({
                query: ASP_DOT_NET_DOMAIN_SETTINGS_PAGE,
                variables: { id: btoa(`Domain:${domainId}`), webPath: path },
            });

            if (!data.domain.webScriptingSettings?.aspDotNet || !data.domain.aspNetSettings?.configs.length) {
                throw new Response('Not Found', { status: 404 });
            }
        },
    }, {
        path: 'permissions',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
            'components/pages/VirtualDirectoryPage/PermissionsPage'
        ),
    }, {
        path: 'php',
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/VirtualDirectoryPage" */
            'components/pages/VirtualDirectoryPage/PhpVersionPage'
        ),
        loader: async ({ request: { url }, params: { domainId } }) => {
            const path = new URL(url).searchParams.get('path') || '/';
            const { data } = await apolloClient.query({
                query: VIRTUAL_DIRECTORY_PHP_PAGE,
                variables: { domainId: btoa(`Domain:${domainId}`), path },
            });

            if (!data.domain.subscription.permissions.managePhpVersion ||
                !data.domain.hosting?.virtualDirectory?.isVirtual
            ) {
                throw new Response('Not Found', { status: 404 });
            }
        },
    }],
};
