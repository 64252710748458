// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useRef, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';
import { render } from 'jsw';

const JswComponent = ({ component: Tag, renderMode, children, ...props }) => {
    const containerRef = useRef();

    useLayoutEffect(() => {
        render(containerRef.current, children, renderMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tag ref={containerRef} {...props} />
    );
};

JswComponent.propTypes = {
    component: PropTypes.string,
    renderMode: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),
};

JswComponent.defaultProps = {
    component: 'div',
    renderMode: undefined,
    children: undefined,
};

export default JswComponent;
