// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LocaleProvider as BaseLocaleProvider } from '@plesk/ui-library';
import { Locale, Observer } from 'jsw';
import { useEventListener } from 'common/hooks';

const mergeMessages = (oldMessages, messages, prefix) => {
    const prefixedMessages = Object.entries(messages)
        .reduce((res, [key, value]) => {
            res[`${prefix}.${key}`] = value;
            return res;
        }, {});

    return {
        ...oldMessages,
        ...prefixedMessages,
    };
};

const LocaleProvider = ({ children }) => {
    const [messages, setMessages] = useState(Object.entries(Locale.sections)
        .reduce((oldMessages, [sectionName, section]) => mergeMessages(oldMessages, section.messages, sectionName), {}));

    const handleAddedLocaleSection = useCallback(({ name, messages }) => {
        setMessages(oldMessages => mergeMessages(oldMessages, messages, name));
    }, []);

    useEventListener('plesk:addedLocaleSection', handleAddedLocaleSection, Observer);

    return (
        <BaseLocaleProvider messages={messages}>
            {children}
        </BaseLocaleProvider>
    );
};

LocaleProvider.propTypes = {
    children: PropTypes.any,
};

LocaleProvider.defaultProps = {
    children: undefined,
};

export default LocaleProvider;
