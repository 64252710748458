// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocalStorage } from 'common/hooks';
import { Panel, Heading } from '@plesk/ui-library';

const SidePanel = ({ baseClassName, className, id, title, children, ...props }) => {
    const [isHidden, setHidden] = useLocalStorage(`${id}-hidden`);

    return (
        <Panel
            className={classNames(baseClassName, className)}
            id={id}
            title={title ? <Heading level={4}>{title}</Heading> : null}
            collapsible={!!id}
            collapsed={title ? isHidden === 'true' : false}
            onToggle={() => setHidden(isHidden === 'true' ? 'false' : 'true')}
            {...props}
        >
            {children}
        </Panel>
    );
};

SidePanel.propTypes = {
    id: PropTypes.string,
    title: PropTypes.any,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

SidePanel.defaultProps = {
    id: undefined,
    title: undefined,
    className: undefined,
    baseClassName: 'side-panel',
};

export default SidePanel;
