// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '@plesk/ui-library';
import { GraphQlListContext } from './GraphQlListContext';

const GraphQlListSearchBar = ({
    name,
    onSearch,
    onTyping,
    ...props
}) => {
    const { listInput, setSearchFilters } = useContext(GraphQlListContext);
    const [value, setValue] = useState(listInput.searchFilters?.[name]);

    return (
        <SearchBar
            inputProps={{ value }}
            {...props}
            onTyping={(...args) => {
                onTyping?.(...args);
                const [value] = args;
                setValue(value);
            }}
            onSearch={(...args) => {
                onSearch?.(...args);
                const [value] = args;
                setValue(value);
                setSearchFilters(currentFilters => ({
                    ...currentFilters,
                    [name]: value,
                }));
            }}
        />
    );
};

GraphQlListSearchBar.propTypes = {
    name: PropTypes.string.isRequired,
    onSearch: PropTypes.func,
    onTyping: PropTypes.func,
};

GraphQlListSearchBar.defaultProps = {
    onSearch: undefined,
    onTyping: undefined,
};

export default GraphQlListSearchBar;
